.Root {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: stretch;

  /* NOTE: 100% width (somewhat) fixes a Flexbug in Chrome where replaced elements (e.g. images don't scale well) */
  width: 100%;

  will-change: transform;

  height: 50%;

  user-select: none;
}
