.FullscreenTile {
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #000;
  z-index: 4;
}

.FullscreenTileWrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.FullscreenTileHeaderWrapper {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;

  display: flex;
  flex-flow: row nowrap;
  padding: 12px;
  pointer-events: none;
}

.FullscreenTileContentOuterWrapper {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  height: 80px;

  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7));

  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding: 12px;
  pointer-events: none;

  font-size: 14px;
  line-height: 1.2;
}

@media (min-width: 960px) {
  .FullscreenTileHeaderWrapper {
    padding: 24px;
  }

  .FullscreenTileContentOuterWrapper {
    height: 150px;
    padding: 24px;
    font-size: 16px;
  }
}

.FullscreenTileDetailModeToggleBtn {
  border: none;
  background: transparent;
  padding: 0;
  margin: 0;
  pointer-events: all;
  cursor: pointer;
  margin-right: 16px;
}

.FullscreenTileContent {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  color: #fff;
}

.FullscreenTileContentInnerWrapper {
  flex: 0 1 auto;
}

.FullscreenTileContent__Caption {
  font-size: 1.4375em;
  font-weight: bold;
}

.FullscreenTileContent__Author {
  font-size: 1em;
  line-height: normal;
}

.FullscreenTileContent__Location {
  margin-right: 24px;
  flex: 0 0 auto;
  width: 48px;
  height: 48px;
}

@media (min-width: 960px) {
  .FullscreenTileContent__Location {
    width: 76px;
    height: 76px;
  }
}

.FullscreenTileContent__VideoIndicator {
  align-self: flex-end;
  margin-left: auto;
}

.FullscreenTilePlayButton {
  pointer-events: all;
  cursor: pointer;
  border: none;
  background: transparent;
  padding: 8px;
}

.FullscreenTileVolumeButton {
  pointer-events: all;
  cursor: pointer;
  border: none;
  background: transparent;
  padding: 8px;
}

.ClampTwoLines {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.ClampOneLine {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.Img {
  display: block;
  height: 100%;
  width: auto;
}

.Video {
  display: block;
  height: 100%;
  width: auto;
}

.Img--Fit {
  width: 100%;
  height: auto;
}

.Video--Fit {
  width: 100%;
  height: auto;
  max-height: 100%;
  max-width: 100%;
}
