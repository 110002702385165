.ScreenContainer {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background-color: #f5f8fa;
  overflow: hidden;
}
