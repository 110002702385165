.Eyebrow {
  line-height: 1.2em;
  text-transform: uppercase;
}

.large {
  font-weight: bold;
  font-size: 14px;
  color: var(--primary-blue-7);
}

.small {
  font-weight: normal;
  font-size: 12px;
  color: var(--neutral-gray-6);
}
