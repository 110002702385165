@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.UploadContainer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  transform: translateY(101%);
  transition: transform 300ms cubic-bezier(.64,.08,.8,.43);
}

/* Button */

.UploadButtonContainer {
  display: flex;
  justify-content: flex-end;
  padding: 1rem;
  position: absolute;
  top: -6.5rem;
  z-index: 1;
}

.UploadButton {
  background: var(--primary-blue-6);
  margin: 0;
  padding: 0;
  border: 0;
  border-radius: 100%;
  width: 4rem;
  height: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.UploadButtonIcon {
  transform: none;
  transition: all 100ms linear;
}


/* Panel */

.UploadPanel {
  background-color: var(--neutral-white);
  border-radius: 1.5rem 1.5rem 0 0;
  padding: 0;
  color: var(--neutral-gray-5);
  height: 75vh;
  overflow: auto;
  width: 100%;
  display: grid;
  grid-template-rows: auto 1fr;
  transform: translateZ(0);
}

.UploadPanelText {
  border-bottom: 1px solid var(--neutral-gray-2);
  margin-bottom: 1rem;
  padding: 1.5rem;
  animation: fadeIn 1s linear;
  animation-fill-mode: forwards;
}

.UploadPanelText h2 {
  color: var(--primary-blue-7);
}

.UploadPanelHeading {
  background: var(--neutral-white);
  position: sticky;
  top: 0;
  left: 0;
  z-index: 2;
  padding: 0.5rem 1.5rem;
  border-bottom: 1px solid var(--neutral-gray-2);
}

@media screen and (max-height: 600px) {
  .UploadPanel {
    height: 60vh;
  }
}

/* Open panel */

.UploadContainerOpen {
  transform: translateY(0);
  z-index: 5;
}
.Scrim {
  display: block;
  background-color: rgba(0,0,0,0.5);
  opacity: 0;
  visibility: hidden;
  height: 100vh;
  position: fixed;
  z-index: -2;
  width: 100%;
  bottom: 0;
  transition: opacity 1s, visibility 1s, z-index 2s;
}

.ScrimVisible {
  opacity: 1;
  visibility: visible;
  height: 100vh;
  z-index: 1;
  transition: opacity 1s, visibility 1s;
}

.UploadContainerOpen .UploadButtonIcon {
  transform: rotate(45deg);
}


/* Buttons */

.ActionButtons {
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  background-color: var(--neutral-white);
  border-top: 1px solid var(--neutral-gray-2);
  padding: 1rem;
  position: sticky;
  bottom: 0;
  left: 0;
  z-index: 2;
  transition: transform 200ms linear;
}

@media screen and (max-width: 600px) {
  .ActionButtons.NotSticky {
    transform: translateY(100%);
  }
}

.Button {
  flex: 1 1 50%;
  cursor: pointer;
}

