/* Uppy style overrides */


.uppy-Root {
  font-family: inherit;
  z-index: 0;
  height: 100%;
}

.uppy-Dashboard {
  height: 100%;
  background-color: var(--neutral-gray-1);
}

.uppy-Dashboard-inner,
.uppy-size--md .uppy-Dashboard-inner {
  width: 100% !important;
  background-color: var(--neutral-gray-1);
  border: 0;
}

@media screen and (min-width: 768px) {
  .uppy-Dashboard-inner,
  .uppy-size--md .uppy-Dashboard-inner {
    height: 150% !important;
    min-height: 0 !important;
  }
}

.uppy-Dashboard-Item {
  flex-direction: column;
  align-items: flex-start;
}

.uppy-Dashboard-Item-preview {
  width: 100% !important;
  height: auto !important;
  max-height: 40vh;
  overflow: hidden;
}

.uppy-Dashboard-Item-fileInfoAndButtons {
  padding: 1rem 0;
  width: 100%;
}

.uppy-Dashboard-Item-name {
  font-size: 1.5rem;
  font-weight: bold;
}

.uppy-StatusBar.is-waiting .uppy-StatusBar-actionBtn--upload {
  background-color: var(--ui-primary-blue);
  padding: 18px 24px;
  border-radius: 4px;
  border: 1px solid var(--ui-primary-blue);
  color: var(--neutral-white);
  transition: all 300ms linear;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 18px;
  font-weight: 700;
}

.uppy-StatusBar.is-waiting .uppy-StatusBar-actionBtn--upload:hover {
  background-color: var(--primary-blue-4);
}

[dir="ltr"] .uppy-Dashboard-Item {
  padding-right: 10px;
}

.uppy-size--lg .uppy-Dashboard-Item,
.uppy-size--md .uppy-Dashboard-Item {
  width: 50%;
}

.uppy-Dashboard-files {
  height: 120vh;
  overflow: hidden;
}

.uppy-Dashboard-files > div {
  min-height: 150% !important;
  height: 100%;
}

@media screen and (min-width: 768px) {
  .uppy-Dashboard-files {
    height: auto;
    overflow: hidden;
  }

  .uppy-Dashboard-files > div {
    height: auto;
    min-height: none !important;
  }
}

.uppy-Dashboard-progressindicators {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
}

@media screen and (max-height: 600px) {
  .uppy-Dashboard-progressindicators {
    position: relative;
  }
}

.uppy-StatusBar-actions {
  justify-content: flex-end;
}


.uppy-size--md .uppy-Dashboard-Item-action--remove {
  width: 20px;
  height: 20px;
}


/* Webcam specific */

.uppy-DashboardContent-panelBody {
  align-items: flex-start;
}

.uppy-Webcam-container {
  height: 60%;
}

.uppy-Webcam-button {
  width: 46px;
  height: 46px;
}


@media screen and (min-width: 768px) {
  .uppy-Webcam-container {
    height: 100%;
  }
}
