
.Input {
  font-family: inherit;
  background: var(--neutral-white);
  border: 1px solid var(--neutral-gray-2);
  border-radius: 0.25rem;
  color: var(--neutral-gray-6);
  padding: 1rem;
  width: 100%;
  font-size: 16px;
}

.Input::placeholder {
  color: var(--neutral-gray-6);
  font-weight: bold;
}

.Error {
  color: red;
  font-size: 0.75rem;
  padding: 0.25rem 0;
  margin: 0;
}
