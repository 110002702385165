.Tile {
  height: 100%;
  position: relative;
  overflow: hidden;
  background-color: var(--ui-primary-blue);
}

.TileHeaderWrapper {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;

  display: none;

  padding: 12px;
  pointer-events: none;
}

.TileVideoPlayIcon {
  pointer-events: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media (min-width: 640px) {
  .TileHeaderWrapper {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
  }

  .TileVideoPlayIcon {
    display: none;
  }
}

.TileContentOuterWrapper {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  height: 80px;

  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7));

  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding: 12px;
  pointer-events: none;

  font-size: 14px;
  line-height: 1.2;
}

@media (min-width: 960px) {
  .TileHeaderWrapper {
    padding: 24px;
  }

  .TileContentOuterWrapper {
    height: 150px;
    padding: 24px;
    font-size: 16px;
  }
}

.TileDetailModeToggleBtn {
  border: none;
  background: transparent;
  padding: 0;
  margin: 0;
  pointer-events: all;
  cursor: pointer;
  margin-right: 8px;
}

.TileContent {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  color: #fff;
}

.TileContentInnerWrapper {
  flex: 0 1 auto;
}

.TileContent__Caption {
  font-size: 1.4375em;
  font-weight: bold;
}

.TileContent__Author {
  font-size: 1em;
  line-height: normal;
}

.TileContent__Location {
  margin-right: 24px;
  flex: 0 0 auto;
  width: 48px;
  height: 48px;
}

@media (min-width: 960px) {
  .TileContent__Location {
    width: 76px;
    height: 76px;
  }
}

.TileContent__VideoIndicator {
  align-self: flex-end;
  margin-left: auto;
}

.ClampTwoLines {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.ClampOneLine {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.Img {
  display: block;
  object-fit: contain;
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: 100%;
}

.Video {
  display: block;
  height: 100%;
  width: 100%;
  object-fit: contain;
}
