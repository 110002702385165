@font-face {
  font-family: 'Brandon Text';
  src: local('Brandon Text Light'), local('BrandonText-Light'),
    url('BrandonText-Light.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Brandon Text';
  src: local('Brandon Text Regular'), local('BrandonText-Regular'),
    url('BrandonText-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Brandon Text';
  src: local('Brandon Text Bold'), local('BrandonText-Bold'),
    url('BrandonText-Bold.woff2') format('woff2');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Brandon Text';
  src: local('Brandon Text Black'), local('BrandonText-Black'),
    url('BrandonText-Black.woff2') format('woff2');
  font-weight: 900;
  font-style: normal;
}
