
.LogoContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;

  position: absolute;
  top: 0;
  right: 0;
  height: 60px;
  z-index: 4;
}

.LogoContainerGradient {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;

  background: linear-gradient(to top, transparent, #171717);
}

.Logo {
  flex: 0 1 195px;
}

.Logo img {
  width: 100%;
  height: auto;
}

.LoginButton {
  margin-left: 1rem;
}

.NotificationButton {
  flex: 0 0 40px;
  margin-left: 1rem;
}

.Mobile .NotificationButton {
  margin-left: 0.5rem;
  width: 40px;
  padding: 0.5rem;
}

@media (min-width: 640px) {
  .LogoContainer {
    height: 121px;
    padding: 32px;
    justify-content: flex-end;
  }

  .Logo {
    width: 274px;
    flex: 0 1 274px;
  }

  .LoginButton {
    margin-left: 2rem;
  }

  .NotificationButton {
    margin-left: 2rem;
    flex: 0 0 auto;
  }
}

