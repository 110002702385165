.Root {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  height: 100%;

  font-size: 36px;
  text-align: center;

  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.92);
  z-index: 6;
}

.Root p {
  margin: 15px 0 30px 0;
}

/* Android Soft-Keyboard Portrait */
@media screen and (max-aspect-ratio: 13/9) and (max-width: 1080px ) {
  .Root {
    display: none;
  }
}
