@import url(fonts/minimal.css);
@import url(~sanitize.css);

/* Colors */

:root {
  /* Neutral */

  --neutral-white: #FFFFFF;
  --neutral-gray-1: #f9f9f9;
  --neutral-gray-2: #e9ecef;
  --neutral-gray-3: #ced4da;
  --neutral-gray-4: #868f96;
  --neutral-gray-5: #5a636b;
  --neutral-gray-6: #3e464d;
  --neutral-gray-7: #212529;

  /* Primary */

  --primary-blue-1: #e7f5ff;
  --primary-blue-2: #a5d8ff;
  --primary-blue-3: #4dabf7;
  --primary-blue-4: #228be6;
  --primary-blue-5: #106bc7; /* main UI blue; different than brand blue for accessibility */
  --primary-blue-6: #004c8f;
  --primary-blue-7: #0d375e;

  --brand-primary-blue: #228BE6; /* brand blue; different than UI blue */
  --ui-primary-blue: var(--primary-blue-5);

  /* Feedback */
  --primary-orange: #e27808;
  --primary-yellow-2: #FEB948;
  --primary-yellow-3: #EB9F23;
  --primary-red-2: #E03131;
  --primary-green-2: #2F9E44;
}

html {
  text-size-adjust: 100%;
}

html,
body,
button {
  font-family: 'Brandon Text', system-ui, /* macOS 10.11-10.12 */ -apple-system,
    /* Windows 6+ */ Segoe UI, /* Android 4+ */ Roboto, /* Ubuntu 10.10+ */ Ubuntu,
    /* Gnome 3+ */ Cantarell, /* KDE Plasma 5+ */ Noto Sans, /* fallback */ sans-serif,
    /* macOS emoji */ 'Apple Color Emoji', /* Windows emoji */ 'Segoe UI Emoji',
    /* Windows emoji */ 'Segoe UI Symbol', /* Linux emoji */ 'Noto Color Emoji';

  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  font-kerning: normal;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;

  /* Brandon Text supports a handful of OpenType font features */
  font-variant-numeric: proportional-nums;
  font-feature-settings: 'liga' on, 'locl' on, 'sups' off, 'case' off, 'ordn' off, 'pnum' on,
    'tnum' off, 'frac' off, 'ss01' off, 'ss02' off;
}

body {
  overflow: hidden;
}

#root {
  width: 100vw;
  /* background-color: #f5f8fa; */
  display: flex;
}
