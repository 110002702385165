
/* New approach based on https://moderncss.dev/custom-select-styles-with-pure-css/ */

.Label {
  --select-border: 1px solid var(--neutral-gray-2);
  --select-bg: var(--neutral-white);
  --select-color: var(--neutral-gray-6);
  --select-focus: var(--ui-primary-blue);
  --select-arrow: var(--ui-primary-blue);
  --select-radius: 0.25rem;
  --select-padding: 1rem;
  font-size: 16px;
}

.Input {
  appearance: none;
  background-color: transparent;
  border: 0;
  color: inherit;
  cursor: inherit;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  margin: 0;
  outline: none;
  padding: 0 1em 0 0;
  width: 100%;
  z-index: 1;
}

.Input::-ms-expand {
  display: none;
}

.Input:focus + .Focus {
  border-radius: inherit;
  bottom: -1px;
  left: -1px;
  outline: 2px dashed var(--select-focus);
  outline-offset: 4px;
  position: absolute;
  right: -1px;
  top: -1px;
  z-index: 0;
}

.SelectWrapper {
  align-items: center;
  background: var(--select-bg);
  border: var(--select-border);
  border-radius: var(--select-radius);
  color: var(--select-color);
  cursor: pointer;
  display: grid;
  grid-template-areas: 'select';
  min-width: 15ch;
  padding: var(--select-padding);
  position: relative;
  width: 100%;
}


.SelectWrapper::after {
  background-color: var(--select-arrow);
  clip-path: polygon(100% 0%, 0 0%, 50% 100%);
  content: '';
  height: 0.5em;
  justify-self: end;
  width: 0.8em;
}

.Input,
.SelectWrapper::after {
  grid-area: select;
}

.disabled {
  cursor: not-allowed;
}

.disabled .SelectWrapper {
  opacity: 0.5;
  pointer-events: none;
}

.HelpText {
  color: var(--neutral-gray-5);
  font-weight: normal;
}

.ErrorText {
  bottom: -1rem;
  color: red;
  font-size: 12px;
  font-weight: normal;
  min-width: 8rem;
  padding-top: 0.25em;
  position: absolute;
}

.errorTextNoPadding {
  bottom: -0.5rem;
}

.ArrowIcon {
  color: var(--ui-primary-blue);
  pointer-events: none;
  position: absolute;
  right: 1rem;
  z-index: 2;
}
