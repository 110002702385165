.Button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 18px 24px;
  border-radius: 4px;
  border: 1px solid var(--button-border);
  background: var(--button-bg);
  pointer-events: all;
  color: var(--button-color);
  transition: all 300ms linear;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 18px;
  font-weight: 700;
}

.Button:hover,
.Button:active {
  background: var(--button-bg--hover);
  border-color: var(--button-bg--hover);
  color: var(--button-color--hover);
  cursor: pointer;
}

.Button:disabled {
  background: var(--neutral-gray-4);
  color: var(--neutral-gray-6);
}

@media screen and (max-width: 600px) {
  .Button {
    padding: 10px 24px;
  }
}

.large {
  padding: 18px 24px;
}

.small {
  padding: 10px 24px;
}


.primary {
  --button-bg: var(--ui-primary-blue);
  --button-color: var(--neutral-white);
  --button-border: var(--ui-primary-blue);
  --button-bg--hover: var(--primary-blue-4);
  --button-color--hover: var(--neutral-white);
}

.secondary {
  --button-bg: transparent;
  --button-color: var(--ui-primary-blue);
  --button-border: var(--ui-primary-blue);
  --button-bg--hover: var(--primary-blue-4);
  --button-color--hover: var(--neutral-white);
}


.link {
  --button-color: var(--ui-primary-blue);
  --button-color--hover: var(--primary-blue-4);
  --button-bg--hover: none;

  padding: 0;
  text-transform: none;
  background: none;
  border: 0;
  text-decoration: underline;
}

