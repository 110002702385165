
/* Caption panel */

.CaptionPanel {
  padding: 1.5rem;
  color: var(--primary-blue-7);
  background-color: var(--neutral-gray-1);
}

@media screen and (min-width: 600px) {
  .CaptionPanel {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
  }
}

.CaptionPanelText ul {
  padding: 0 0 1.5rem 1.5rem;
}

.CaptionForm {
  margin-bottom: 1.5rem;
}

.ImageFrameWrapper {
  display: flex;
  height: auto;
  max-height: 60vh;
}

.ImageFrame {
  background-color: var(--neutral-white);
  border: 1px solid var(--neutral-gray-2);
  padding: 0.75rem;
  position: relative;
  width: auto;
  height: 100%;
  margin: 0;
}

.ImageFrame img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  max-height: 60vh;
}

@media screen and (max-width: 600px) {
  .ImageFrameWrapper {
    height: auto;
    max-height: none;
  }

  .ImageFrame img {
    width: 100%;
    height: auto;
    max-height: none;
  }
}


.ImageCaption {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  padding: 1.5rem;
}

.ImageCaption .Location {
  margin-right: 0.5rem;
  width: 4rem;
  height: 4rem;
  flex: 0 0 4rem;
}

.FigureText {
  color: var(--neutral-white);
  font-size: 1.25rem;
}

.FigureText cite {
  display: block;
  font-style: normal;
  font-size: 1.125rem;
}
