.LoveButtonWrapper {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;

  display: flex;
  justify-content: flex-end;

  padding: 12px;
  pointer-events: none;
  z-index: 2;
}

.LoveButton {
  border: none;
  background: transparent;
  padding: 0;
  margin: 0;
  pointer-events: all;
  cursor: pointer;
}

.active .HeartIcon {
  fill: var(--neutral-white);
}

.LoveCanvas {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
