.NotifierButton {
  background: none;
  display: block;
  border: 0;
  z-index: 100;
  width: 50px;
  cursor: pointer;
  position: relative;
}

.NotificationIcon {
  width: 100%;
  height: 100%;
}

.NotificationIcon path {
  fill: white;
  transition: fill 200ms linear;
}

.NotifierButton:hover .NotificationIcon path {
  fill: var(--ui-primary-blue);
}

.NotificationOverlay {
  background-color: rgba(0,0,0,0.8);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 5rem;
  z-index: 99;
}

.OverlayContent {
  font-size: 1.75rem;
  width: 100%;
  max-width: 600px;
}

.OverlayHeading {
  font-weight: bold;
  font-size: 3.375rem;
  line-height: 1em;
  text-transform: uppercase;
}

.OverlayArrow {
  position: absolute;
  right: 3rem;
  top: 10rem;
}


@media screen and (max-width: 600px) {
  .NotificationOverlay {
    padding: 3rem;
  }

  .OverlayContent {
    font-size: 1rem;
  }

  .OverlayHeading {
    font-size: 2rem;
  }

  .OverlayArrow {
    right: 1.5rem;
  }
}

.Banner {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 12px;
  text-align: center;
  background-color: var(--ui-primary-blue);
  z-index: 10;
}
