.Root {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #f5f8fa;
  z-index: 4;
}

.Inner {
  font-size: 36px;
  color: #444444;
  max-height: 100%;
  max-width: 100%;
  text-align: center;
}

@media (min-width: 640px) {
  .Inner {
    font-size: 72px;
  }
}
